import React from 'react';
import './styles.scss';

const Testimonials = props => {
    return (
        <section className="testimonials">
            <div className="testimonials__container">
                <div className="testimonials__col-small">
                    <h2 className="testimonials__heading">{props.statsNumber}</h2>
                    <p className="testimonials__sub-heading">{props.statsText}</p>
                </div>
                <div className="testimonials__col-big">
                    <p className="testimonials__quote">{props.quote}</p>

                    <div className="testimonials__author">
                        <div className="testimonials__author-image">
                            <img src={props.quoteAuthorImg} alt="" />                            
                        </div>
                        <div className="testimonials__author-text">
                            <h3 className="testimonials__author-name">{props.quoteAuthor}</h3>
                            <p className="testimonials__author-company">{props.quoteAuthorTitle}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Testimonials;