import React from 'react';
import classNames from 'classnames';
import './styles.scss';

const BulletList = props => {
    return (
        <ul className={classNames({
            'bullet': true,
            ...props.modifiers
        })}>{props.children}</ul>
    );
};

export default BulletList;