import React from 'react';
import classNames from 'classnames';
import './styles.scss';

const Product = props => {
    return (
        <div className={classNames({
            'product': true,
            'product--is-left': props.textLeft,
            ...props.modifiers
        })}>
            <div className="product__content">
                {props.textLeft &&
                    <>
                        <div className="product__col">
                            <h2 className="product__heading">
                                {props.heading}
                            </h2>
                            {props.children}
                        </div>
                        <div className="product__col">
                            <div className="product__image">
                                <img src={props.img} alt="" />
                            </div>
                        </div>
                    </>
                }
                {!props.textLeft &&
                    <>
                        <div className="product__col">
                            <div className="product__image">
                                <img src={props.img} alt="" />
                            </div>
                        </div>
                        <div className="product__col">
                            <h2 className="product__heading">
                                {props.heading}
                            </h2>
                            {props.children}
                        </div>
                    </>
                }
            </div>
        </div>
    );
};

export default Product;