import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import Layout from '../../components/layout';
import PageHeading from '../../components/pageheading';
import Product from '../../components/product';
import Customers from '../../components/customers';
import CallToAction from '../../components/calltoaction';
import Testimonials from '../../components/testimonials';
import Integrations from '../../components/integrations';
import BulletList from '../../components/bulletlist';
import ann from '../../images/persons/ann.jpg';
import img from '../../images/map@2x.png';
import salesDistrictImg from '../../images/content/salesdistrict.png';
import salesDistrictImg2 from '../../images/content/register-bild.png';

const SalesDistricts = props => {
    return (
        <Layout>
            <Helmet>
                <title>Delfi.se - Butiksregister & kartverktyg</title>
            </Helmet>
            <article>
                <PageHeading heading="Butiksregister & kartverktyg" 
                    preamble="Delfi är din informationskälla till dagligvaruhandel, servicehandel, hälsa och foodservice." 
                    img={img} />
                <Product modifiers={{
                    'product--gray': true
                }} 
                heading="Planera dina säljdistrikt"
                img={salesDistrictImg}
                callToAction="Boka ett demomöte"
                textLeft={true}>
                    <p>
                        Utnyttjar du dina säljresurser fullt ut? Med Delfis verktyg för säljdistriktsplanering, optimerar ni er säljkårs geografiska distrikt för att uppnå högre effektivitet och bättre resultat. Med enkla och användarvänliga kartfunktioner kan ni själva skapa era säljdistrikt med direkt tillgång till Delfis butiksregister med aktuell information. Här följer några av alla funktioner i tjänsten:
                    </p>
                    <BulletList>
                        <li>Planera, skapa och bevaka säljdistrikt i vår webbtjänst</li>
                        <li>Visualisera dina säljares distrikt och butiker på karta</li>
                        <li>Säljdistriktsrapport med butiker, kedjor, nyckeltal, omsättning och säljyta</li>
                        <li>Kategorisering – Antal besök/säljcykel, A-B-C kunder, säljcykler</li>
                        <li>Exportera komplett och aktuell Delfi information för dina säljdistrikt</li>
                        <li>SCB-data med demografiska variabler</li>
                    </BulletList>
                    <Link to="/demo" className="button button--large">Boka ett demomöte</Link>
                </Product>

                <Product heading="Butiksregister för effektivitet och affärsmöjligheter"
                         img={salesDistrictImg2}>
                    <p>
                        Delfi är Sveriges största databas över dagligvarubranschen
                        Databasen omfattar 40 000 av Sveriges aktörer inom dagligvaruhandeln, servicehandeln och foodservice. Delfi databasen är unik då den innehåller både basuppgifter samt tilläggsuppgifter som kedjetillhörighet, huvudgrossist, omsättning, GLN, geografiska koordinater, antal serverade portioner och mycket mer.
                        Som abonnent till vårt butiksregister får du tillgång till vår digitala plattform med hela registret. Ni får även prenumerationsfiler levererade till ert CRM-system. Smidiga integrationsmöjligheter med API eller fil.
                    </p>
                    <Link to="/demo" className="button button--large">Boka ett demomöte</Link>
                </Product>

                <Testimonials statsNumber="44 412"
                              statsText="enheter inom dagligvaruhandel, servicehandel, foodservice samt hälsa och egenvård finns tillgängliga i Delfi."
                              quote="Ett uppdaterat butiksregister ger dig effektivitet och affärsmöjligheter. Delfi uppdateras dagligen och ger dig en hög kvalitetsnivå. Daglig, veckovis eller månadsvis? Vi levererar uppdaterade register till dig i det format som passar era CRM- och andra system."
                              quoteAuthor="Ann Tindberg" 
                              quoteAuthorTitle="Delfi Register, Säljdistrikt" 
                              quoteAuthorImg={ann} />

                <Integrations />

                <CallToAction heading="Vill du veta mer om butiksregister & kartverktyg?">
                    <Link to="/demo" className="button button--large">Boka ett demomöte</Link>
                </CallToAction>

                <Customers heading="Vi har levererat information till branschen sedan 1987" />
            </article>
        </Layout>
    );
};

export default SalesDistricts;
