import React from 'react';
import './styles.scss';

const CallToAction = props => {
    
    
    return (
        <div className="call-to-action">
            <div className="call-to-action__container">
                <h2 className="call-to-action__heading">{props.heading}</h2>
                {props.children}
            </div>
        </div>
    );
};

export default CallToAction;