import React from 'react';
import img1 from '../../images/content/genero@2x.png';
import img2 from '../../images/content/nordman@2x.png';
import './styles.scss';

const Integrations = props => {
    return (
        <section className="integrations">
            <h2 className="integrations__heading">Integrationsmöjligheter</h2>
            <p className="integrations__preamble">Delfi tillhandahåller färdiga integrationer till flera marknadsledande CRM-system.</p>
        
            <div className="integrations__integration-list">
                <div className="integrations__integration">
                    <div className="integrations__integration-ratio"></div>
                    <img src={img2} alt="" />
                </div>
                <div className="integrations__integration">
                    <div className="integrations__integration-ratio"></div>
                    <img src={img1} alt="" />
                </div>
            </div>
        </section>
    );
};

export default Integrations;